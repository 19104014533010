<template>
  <div class="address-list">
    <div class="table-box">
      <div class="table-title">{{ $t("地址") }}</div>
      <el-table :data="tableData" stripe style="width: 100%">
        <el-table-column
          prop="address"
          :label="$t('地址')"
          :width="$isInMobile ? wids : ''"
        >
        </el-table-column>
        <el-table-column
          prop="ping"
          :label="$t('平衡')"
          :width="$isInMobile ? wids : ''"
        >
        </el-table-column>
        <el-table-column
          prop="account"
          :label="$t('TXN計數')"
          :width="$isInMobile ? wids : ''"
        >
        </el-table-column>
      </el-table>
      <section class="pagition-section">
        <el-pagination
          layout="prev, pager, next"
          :total="total"
          background
          @current-change="handelCurrentPage"
          :page-size="limit"
          :page-count="5"
        >
        </el-pagination>
      </section>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs } from "vue";
export default {
  components: {},
  setup() {
    const data = reactive({
      page: 1,
      total: 40,
      limit: 10,
      wids: "200",
      tableData: [],
      handelCurrentPage: (val) => {
        data.page = val;
        data.getTableList();
      },
      getTableList: () => {
        // data.loading = true;
        //   getList({
        //     wallet_name: data.type,
        //     page: data.page,
        //     limit: data.limit
        //   })
        //     .then(res => {
        //       data.loading = false;
        //       data.tableData = res.data.data;
        //       data.total = res.data.total;
        //     })
        //     .catch(() => {
        //       data.loading = false;
        //     });
      },
    });
    const refData = toRefs(data);
    return {
      ...refData,
    };
  },
};
</script>
<style lang="scss" scoped>
.address-list {
  max-width: 1400px;
  margin: 30px auto 80px;
}
@media screen and (max-width: 480px) {
  .table-box {
    margin: 0 15px;
    .table-title {
      font-size: 18px;
      height: 40px;
      line-height: 46px;
      padding: 0 15px;
    }
  }
}
</style>
